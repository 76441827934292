<!-- 审核详情 -->
<template>
  <div>
    <back />
    <div class="applyDetail" v-loading="loading">
      <h3 class="title">申请详情:</h3>

      <!-- 支付宝 -->
      <div v-if="detail?.accountType == '1'">
        <el-row class="row">
          <el-col :span="7">
            <div class="col">申请单号:{{ detail?.applyNum }}</div>
            <div class="col">账号:{{ detail?.accountNum }}</div>
            <div class="col">状态:{{ detail?.status == 0 ? '待审核' : detail?.status == 1 ? '审核通过' : '审核不通过' }}</div>
          </el-col>
          <el-col :span="6">
            <div class="col">骑手姓名:{{ detail?.applyUserName }}</div>
            <div class="col">提现金额：<span v-if="detail?.applyAmount">{{
      detail?.applyAmount + '元'
    }}</span></div>
            <div class="col">审核时间:{{ detail?.updatedTime }}</div>
          </el-col>
          <el-col :span="5">
            <div class="col">账户类型:{{ detail?.accountType == '1' ? '支付宝' : '银行卡' }}</div>
            <div class="col">可提现金额：<span v-if="detail?.availableAmount">{{
      detail?.availableAmount + '元'
    }}</span></div>
          </el-col>
          <el-col :span="6">
            <div class="col">账户名称:{{ detail?.accountName }}</div>
            <div class="col">申请时间:{{ detail?.createdTime }}</div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col class="col red" v-if="detail?.status == '2'">不通过原因:{{ detail?.auditMsg }}</el-col>
        </el-row>
      </div>

      <!-- 银行卡 -->
      <div v-if="detail?.accountType == '2'">
        <el-row class="row">
          <el-col :span="6">
            <div class="col">申请单号:{{ detail?.order }}</div>
            <div class="col">账户名称:{{ detail?.accountName }}</div>
            <div class="col">申请时间:{{ detail?.createdTime }}</div>
          </el-col>
          <el-col :span="6">
            <div class="col">骑手姓名:{{ detail?.applyUserName }}</div>
            <div class="col">账号:{{ detail?.account }}</div>
            <div class="col">状态:{{ detail?.status == 0 ? '待审核' : detail?.status == 1 ? '审核通过' : '审核不通过' }}</div>
          </el-col>
          <el-col :span="6">
            <div class="col">账户类型:{{ detail?.accountType == '1' ? '支付宝' : '银行卡' }}</div>
            <div class="col">提现金额：<span v-if="detail?.applyAmount">{{
      detail?.applyAmount + '元'
    }}</span></div>
            <div class="col">审核时间:{{ detail?.updatedTime }}</div>
          </el-col>
          <el-col :span="6">
            <div class="col">支行名称:{{ detail?.branchName }}</div>
            <div class="col">可提现金额：<span v-if="detail?.availableAmount">{{
      detail?.availableAmount + '元'
    }}</span></div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col class="col red" v-if="detail?.status == '2'">不通过原因:{{ detail?.auditMsg }}</el-col>
        </el-row>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      detail: {},
      id: '',
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getApplyDetail();
  },
  methods: {
    getApplyDetail() {
      this.loading = true;
      this.$axios.get(`${this.$api.getRiderExtractApplyDetail}/${this.id}`).then((res) => {
        if (res.data.code == 100) {
          this.detail = res.data.result;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
  }

}

</script>

<style lang="scss" scoped>
.applyDetail {
  padding: 20px;

  .title {
    margin-bottom: 25px;
  }

  .row {
    color: #707070;
  }

  .col {
    margin-bottom: 25px;
  }

  .red {
    color: red;
  }

}
</style>